import { render, staticRenderFns } from "./Header2Section.vue?vue&type=template&id=334c9ca2&scoped=true&"
import script from "./Header2Section.vue?vue&type=script&lang=ts&"
export * from "./Header2Section.vue?vue&type=script&lang=ts&"
import style0 from "./Header2Section.vue?vue&type=style&index=0&id=334c9ca2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "334c9ca2",
  null
  
)

export default component.exports