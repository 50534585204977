
import { DataClient } from "@/services/DataClient";
import { useLocationStore } from "@/stores/LocationStore";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouterView } from "vue-router";

import Header1Section from "@/sections/Header1Section.vue";
import Header2Section from "@/sections/Header2Section.vue";
import { BaseView } from "@/services/BaseView";
import { loadLanguageAsync } from "@/i18n";

@Component({
    components: {
        Header1Section,
        Header2Section,
    },
})
export default class extends BaseView {
    sidebar = false;
    language = this.$i18n.locale;

    showLanguageSelector = true;

    targetElevation = 0;
    targetBackgroundColor = "transparent";

    languages = [
        {
            title: "Español",
            code: "es",
        },
        {
            title: "English",
            code: "en",
        },
        {
            title: "Deutsch",
            code: "de",
        },
        {
            title: "Français",
            code: "fr",
        },
        {
            title: "Italiano",
            code: "it",
        },
    ];

    // @Watch("$vuetify.breakpoint.smAndDown", { immediate: true })
    // onBreakpointChanged(value: boolean) {
    //     console.log("onBreakpointChanged", value);
    //     this.sidebar = false;
    // }

    created() {
        useLocationStore().initialize();
    }

    get mallorca() {
        const category = DataClient.instance.getCategoryById("9b5df3c6-0747-46a3-9fce-79ea05f38cc4");
        return this.buildNavigationRoute("category", { id: category.slug });
    }

    get menorca() {
        const category = DataClient.instance.getCategoryById("73da6181-2950-4b6d-834c-d7af7da8bcd6");
        return this.buildNavigationRoute("category", { id: category.slug });
    }

    get ibiza() {
        const category = DataClient.instance.getCategoryById("f7f9588a-a756-4b7a-94bb-37fc6227c049");
        return this.buildNavigationRoute("category", { id: category.slug });
    }

    async setLanguage(code: string) {
        // await DataClient.instance.loadConfiguration(code);
        // this.$router.push({ name: "home" + "." + code });

        const oldName = (this.$route.name || "").slice(0, -3);
        let id: string | undefined;

        console.log(oldName);

        if (oldName === "category") {
            id = DataClient.instance.getCategoryBySlug(this.$route.params.id as string)?.id;
        } else if (oldName === "activity") {
            id = DataClient.instance.getActivityBySlug(this.$route.params.id as string)?.id;
        }

        const newName = oldName + "." + code;

        // make sure configuration is loaded
        DataClient.instance.loadConfiguration(code).then(async () => {
            await loadLanguageAsync(code);
            this.$i18n.locale = code;

            if (oldName === "category" && id) {
                this.$router.push({ name: newName, params: { id: DataClient.instance.getCategoryById(id)?.slug }, query: this.$route.query });
            } else if (oldName === "activity" && id) {
                this.$router.push({ name: newName, params: { id: DataClient.instance.getActivityById(id)?.slug }, query: this.$route.query });
            } else {
                this.$router.push({ name: newName, params: this.$route.params, query: this.$route.query });
            }
        });
    }

    get headerType() {
        return DataClient.instance.configuration.headerType;
    }

    agentsLogin() {
        window.open("https://agents.viajescaledonia.com", "_blank");
    }
}
